import { Col } from "react-bootstrap";


function ConditionalLink({ url, children }) {
  console.log(url)

  // If the URL is not provided or is an empty string, render a span instead
  if (url === "") {
    return <span>{children}</span>;
  }

  // If the URL is provided, render the anchor tag
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export const ProjectCard = ({ title, description, imgUrl, demoLink }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <ConditionalLink url={demoLink}>

        <div className="proj-imgbx">
          <img src={imgUrl} />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      </ConditionalLink>

    </Col>
  )
}
