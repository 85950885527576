import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/beachhacks.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
// Updated import statement
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from "react";

// Set up workerSrc for PDF.js, which react-pdf uses under the hood
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ pdf }) {
  return (
    <iframe 
      src={pdf}
      width="100%"
      height="500px"
      style={{ border: 'none' }}>
    </iframe>
  );
}


export const Projects = () => {
  const pdfFile = '/pdf/FadiJarrayResume.pdf';

  const projects = [
    {
      title: "Funniest Hack",
      description: "BeachHacks 2022 Winner",
      imgUrl: projImg1,
      demoLink: "https://devpost.com/software/the-slap?ref_content=my-projects-tab&ref_feature=my_projects",
    },
    {
      title: "FPGA VerilogPong",
      description: "FPGA Game Design & Development",
      imgUrl: projImg2,
      demoLink: "https://github.com/Jfadi/VerilogPong",
    },
    {
      title: "WAIV Desktopp App",
      description: "Electron Powered React App",
      imgUrl: projImg3,
      demoLink: "https://github.com/Jfadi/WAIV_DesktopApp",
    },
  ];

  return (
    <section className="project" id="projects" style={{height : "100%"}}>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Experience & Projects</h2>
                  <p>Click on the projects for demos!</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Resume</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Projects</Nav.Link>
                      </Nav.Item>

                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="first" >
                        <div >
                          <PDFViewer pdf={pdfFile} />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
