import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ message, onValidated }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (status === 'success') { clearFields(); }
  }, [status])

  const handleSubmit = (event) => {
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email
      })
    event.preventDefault();
    const form = event.target;
    const scriptURL = 'https://script.google.com/macros/s/AKfycbzVldAFE2f_n99WeylL1QtPSQ1CRiSfFoelV5FzicVM80aH7aBp62mTUjwVzMQZNWS-/exec';

    fetch(scriptURL, {
      method: 'POST',
      body: new FormData(form)
    })
      .then(response => {
        setStatus(true);
        alert("Subscription Successful! Thank you!");
        clearFields();
      })
      .catch(error => {
        console.error('Error!', error.message)
        setStatus(false);
      });
  };

  const clearFields = () => {
    setEmail('');
  }

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Subscribe to my Newsletter<br></br> & Never miss latest updates</h3>
            {status === 'sending' && <Alert>Sending...</Alert>}
            {status === 'error' && <Alert variant="danger">{message}</Alert>}
            {status === 'success' && <Alert variant="success">{message}</Alert>}
          </Col>
          <Col md={6} xl={7}>
            <form onSubmit={handleSubmit} name="submit-to-google-sheet">
              <div className="new-email-bx">
                <input name="Email" value={email} type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                <button type="submit">Submit</button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  )
}
