import networking from "../assets/img/networking.svg";
import gitlab from "../assets/img/gitlab.svg";
import docker from "../assets/img/docker.svg";
import mqtt from "../assets/img/mqtt.svg";
import react from "../assets/img/react.svg";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <h3>Languages: </h3><p>C++, C, JavaScript, Java, Lua, Python, Bash, HTML, CSS, Verilog</p>
                        <h3>Technologies: </h3><p>React.js, JQuery, Node.js, NextJs, Git, Gitlab CI/CD, Bootstrap, Figma, Electron, MongoDB, Gitlab, Linux, Docker, REST APIs, JSON</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={networking}/>
                                <h5>Computer Networking</h5>
                            </div>
                            <div className="item">
                                <img src={gitlab} />
                                <h5>Gitlab CI/CD</h5>
                            </div>
                            <div className="item">
                                <img src={docker} />
                                <h5>Docker</h5>
                            </div>
                            <div className="item">
                                <img src={mqtt} />
                                <h5>MQTT</h5>
                            </div>
                            <div className="item">
                                <img src={react} />
                                <h5>ReactJs</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} />
    </section>
  )
}
